import { useContext, useState } from "react";
import { faceLogin } from "@privateid/cryptonets-web-sdk";
import { getRawFaceValidationStatus } from "@privateid/cryptonets-web-sdk/dist/utils";
import { useSearchParams } from "react-router-dom";
import { UserContext } from "context/userContext";

let userNotEnrolled = 0;
let USER_NOT_ENROLLED_THRESHOLD = 15;
const useFaceLoginWithLivenessCheck = (
    setShowSuccess: any = () => {},
    onSetStatus?: (e: number) => void,
    retryTimes = 3,
    isInitialPredict = true,
    onPredictFail?: any
) => {
  const context: any = useContext(UserContext);
  const configuration = context?.configuration;
  const [searchParams] = useSearchParams();
  const [faceLoginWithLivenessMessage, setPredictMessage] = useState("");
  const [loginGUID, setLoginGUID] = useState("");
  const [loginPUID, setLoginPUID] = useState("");
  const [faceLoginInputImageData, setFaceLoginInputImageData] =
      useState<any>(null);
  const [faceLoginData, setPredictData] = useState<any>(null);
  const [faceLoginResponseStatus, setFaceLoginResponseStatus] =
      useState<number>();
  let tries = 0;
  const faceLoginWithLiveness = async (skipAntispoof = false) => {
    // @ts-ignore
    const inputImage = await faceLogin({
      callback: callback,
      config: {
        skipAntispoof: searchParams.get("skipAntispoof") === "true" || !configuration?.liveness || false,
        allowed_results: [10],
        // disallowed_results: [10],
        // threshold_glass: 100.0
      },
    });

    setFaceLoginInputImageData(inputImage);
  };

  const callback = async (result: any) => {
    console.log("faceLogin callback hook result:", result);
    if (![0, 10].includes(result.face_validation_status)) {

      if(result.face_validation_status === 8) {
        setPredictMessage("Move face into frame");
      } else {
        setPredictMessage(getRawFaceValidationStatus(result.face_validation_status));
      }

      faceLoginWithLiveness();
      return;
    }
    // setFaceLoginInputImageData(result?.imageData)
    handleWasmResponse(result);
  };

  const handleWasmResponse = (returnValue: any) => {
    if (returnValue?.face_validation_status !== -100) {
      setFaceLoginResponseStatus(returnValue?.face_validation_status);
    }
    if ([0, 10].includes(returnValue.face_validation_status) && returnValue?.guid && returnValue?.puid) {
      handleValidImage(returnValue);
    } else if (returnValue.face_validation_status === -7) {
      handleUserNotEnrolled(returnValue);
    } else {
      handleInvalidImage(returnValue);
    }
  };

  const handleUserNotEnrolled = (returnValue: any) => {
    const { message = "", validation_status } = returnValue || {};
    if (userNotEnrolled < USER_NOT_ENROLLED_THRESHOLD) {
      userNotEnrolled++;
      faceLoginWithLiveness();
      setPredictMessage("Processing");
    } else {
      setShowSuccess(returnValue);
      setPredictData({
        ...returnValue,
        retryComplete: userNotEnrolled >= USER_NOT_ENROLLED_THRESHOLD,
      });
      userNotEnrolled = 0;
      onPredictFail();
    }
  };
  const handleValidImage = (returnValue: any) => {
    setPredictMessage("Valid Image");
    setPredictData({
      ...returnValue,
      retryComplete: !isInitialPredict && tries === retryTimes,
    });
    setLoginGUID(returnValue.guid);
    setLoginPUID(returnValue.puid);
    setShowSuccess(returnValue);
  };

  const handleInvalidImage = (returnValue: any) => {
    if (tries !== retryTimes) {
      if (isInitialPredict) {
        tries += 1;
      }
      faceLoginWithLiveness();
    } else {
      setShowSuccess(returnValue);
      setPredictData({
        ...returnValue,
        retryComplete: !isInitialPredict && tries === retryTimes,
      });
      tries = 0;
    }
  };
  const resetFaceLogin = (callFunc = true) => {
    setPredictData(undefined);
    setFaceLoginInputImageData(null);
    setPredictMessage("");
    if (callFunc) {
      faceLoginWithLiveness();
    }
  };

  return {
    faceLoginWithLiveness,
    faceLoginWithLivenessMessage,
    faceLoginInputImageData,
    faceLoginData,
    resetFaceLogin,
    faceLoginResponseStatus,
    loginGUID,
    loginPUID,
  };
};

export default useFaceLoginWithLivenessCheck;
