import { Button } from "components/ui/button";
import { Label } from "components/ui/label";
import { UserContext } from "context/userContext";
import { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getUser, verifyTokenApi } from "services/api";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import { useNavigation } from "utils/onNavigate";
import { useInterval } from "utils/useInterval";
import Layout from "V2/layout";
import phone from "../../assets/V2/phone.png";

interface componentNameProps {}

const MagicLink = (props: componentNameProps) => {
  const { navigateToNextPage } = useNavigation();
  const context: any = useContext(UserContext);
  const { navigateWithQueryParams } = useNavigateWithQueryParams();

  const [searchParams] = useSearchParams();
  const tokenParams: any = searchParams.get("token");
  const [refreshInterval, setRefreshInterval] = useState<null | number>(10000);
  useInterval(() => {
    verifyTokenApi(tokenParams).then(async (res: any) => {
      if (["SUCCESS", "FAILURE"].includes(res.status)) {
        setRefreshInterval(null);
        if (res.status === "SUCCESS") {
          const payload = {
            id: res.user,
          };
          const data: any = await getUser(payload);
          localStorage.setItem("uuid", JSON.stringify(data.user.uuid || {}));
          successSessionRedirect(tokenParams, false, false);
        } else {
          failureSessionRedirect(tokenParams, false);
        }
      }
    });
  }, refreshInterval);

  const failureSessionRedirect = (
    session: { failureUrl: string | URL },
    redirectUrl = false
  ) => {
    if (redirectUrl && session.failureUrl) {
      setTimeout(() => {
        window.location.replace(session.failureUrl);
      }, 2000);
    } else {
      return navigateWithQueryParams("/v2/failure-screen");
    }
  };

  const successSessionRedirect = (
    session: { successUrl: string | URL },
    redirectUrl = false,
    passKey = true
  ) => {
    context?.setV2SuccessMessage("You're logged in");
    return navigateWithQueryParams("/v2/success");
  };
  return (
    <Layout backgroundColor={"#161b25"}>
      <div className="pt-[40px] px-[40px] h-[100%] flex flex-col justify-between ">
        <div className=" flex flex-col h-full items-center align-middle justify-center">
          <div className=" mx-auto  flex justify-center">
            <img src={phone} className="w-[150px] h-[150px]" />
          </div>

          <Label className="text-[25px]  font-[500] pt-10 pb-5  text-white block text-center">
            Check your phone
          </Label>
          <Label className="text-[17px]  font-[400] text-white block text-center opacity-70">
            A text message with a link was just sent to the phone ending in 
            {` ${context?.user?.phone?.slice(6)}` || ' XXXX'}. Click the link to verify your face and complete registration.{" "}
          </Label>
        </div>
        <div className="flex flex-col space-y-4 justify-center items-center pb-[0px] max-md:pb-[6rem]">
          <Button
            className="w-[65%] text-[16px] hover:bg-transparent text-[#ffffff] bg-transparent underline"
            onClick={() =>
              navigateToNextPage("", "/v2/user-info-sms", "/v2/magic-link")
            }
          >
            I did not receive the text
          </Button>
          <Button
            className=" max-md:mb-[0px] w-full text-black bg-white rounded-[16px] mt-4 h-[56px] hover:opacity-90 hover:bg-white"
            onClick={() => navigateWithQueryParams("/")}
          >
            Return to homepage
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default MagicLink;
