import { Button } from "components/ui/button";
import { UserContext } from "context/userContext";
import { useContext, useState } from "react";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import { useNavigation } from "utils/onNavigate";
import MainLogo from "../../assets/login/logo-main.png";
import { getOrganizationFromURL } from "utils";
import { EQUIFAX_FLOWS, PASSKEY_BASE_URL } from "constant";
import { ChevronDown } from "lucide-react";
import useWasm from "hooks/useWasm";
import {
  dropGenerateRegistrationOptions,
  generateRegistrationOptions,
  verifyRegistration,
} from "services/passkey";
import { useToast } from "components/ui/use-toast";
import { startRegistration } from "@simplewebauthn/browser";
import { deleteUser } from "@privateid/cryptonets-web-sdk";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { GearIcon } from "@radix-ui/react-icons";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";
import { useSearchParams } from "react-router-dom";

const Header = ({ onChangeFlow }: any) => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const {
    configuration,
    setLoginOption,
    pageConfiguration,
    activeFlow,
    setPreviousRoutes,
  }: any = useContext(UserContext);
  const projectName = getOrganizationFromURL();
  const [searchParams, setSearchParams] = useSearchParams();
  const isChase = projectName === "chase";
  const pageConfig: any = pageConfiguration;
  const logo = configuration?.logo || MainLogo;
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { navigateToNextPage } = useNavigation();
  const { toast } = useToast();
  const [isMenu, setIsMenu] = useState(false);
  const { ready: wasmReady, wasmStatus } = useWasm();
  const onSignin = () => {
    setLoginOption("faceLogin");
    setPreviousRoutes([]);
    const url = projectName === "twilio" ? '/v2/login' : configuration?.multiframelogin
      ? "/multi-frame-login"
      : "/face-login";
    if (pageConfig) {
      navigateToNextPage(window.location.search?.replace("?", ''), url, "/");
    } else {
      navigateWithQueryParams(url);
    }
  };

  const toggelMenu = () => {
    setIsMenu(!isMenu);
  };

  const onLogout = () => {
    localStorage.removeItem("uuid");
    localStorage.removeItem("user");
    toggelMenu();
  };

  const onGeneratePasskey = async () => {
    toggelMenu();
    const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
    let response;
    try {
      response = await generateRegistrationOptions(uuid);
    } catch (error: any) {
      toast({
        variant: "destructive",
        description: "There was some issue generating with passkey.",
      });
      return;
    }
    let attResp;
    try {
      const opts = response;
      attResp = await startRegistration(opts);
    } catch (error: any) {
      toast({
        variant: "destructive",
        description: "There was some issue generating with passkey.",
      });
      return;
    }
    const verificationJSON = await verifyRegistration({ attResp, uuid });
    if (verificationJSON?.verified) {
      toast({
        variant: "success",
        description: "Passkey successfully generated.",
      });
    }
  };

  const dropPasskey = async () => {
    toggelMenu();
    const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
    let response;
    // try {
    //   response = await dropGenerateRegistrationOptions(uuid);
    // } catch (error: any) {
    //   toast({
    //     variant: "destructive",
    //     description: "Failed to generate passkey",
    //   });
    //   navigateToNextPage("", "/", "/");
    //   return;
    // }
    let attResp;
    try {
      const opts = response;
      // attResp = await startRegistration(opts);
    } catch (error: any) {}
    const verificationJSON = await verifyRegistration({ attResp, uuid: uuid });
    if (verificationJSON?.verified) {
      toast({
        variant: "destructive",
        description: "Passkey Successfully Droped",
      });
      navigateToNextPage("", "/", "/");
    }
  };

  const dropPasskeyWithCard = async () => {
    const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
    let attResp;
    try {
      const response = await fetch(PASSKEY_BASE_URL + "/generate-registration-options", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ uuid: uuid, cardAuth: true }), // body data type must match "Content-Type" header
      });
      try {
        const opts = await response.json();
        attResp = await startRegistration(opts);
      } catch (error: any) {
        return;
      }
    } catch (error) {
        console.log(error,'error');
        
    }
    const verificationResp = await fetch(PASSKEY_BASE_URL + "/verify-registration", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ data: attResp, uuid: uuid }),
    });

    const verificationJSON = await verificationResp.json();
    // console.log(198, { verificationJSON });
    if (verificationJSON?.verified) {
      toast({
        variant: "success",
        description: "Passkey Successfully Droped",
      });
    }
  };

  const onDeleteUser = () => {
    onLogout();
    toast({
      variant: "success",
      description: "User Deleted Successfully",
    });
  };

  const handleDeleteUser = async () => {
    toggelMenu();
    const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
    try {
      deleteUser({ uuid, callback: onDeleteUser });
    } catch (error: any) {
      toast({
        variant: "destructive",
        description: "Failed to delete user",
      });
      navigateToNextPage("", "/", "/");
      return;
    }
  };

  const onRegisterChange = (e: any) => {
    const value = e?.target?.value;
    const params = new URLSearchParams(window.location.search);
    params.set("mf_count", value);
    searchParams.set("mf_count", value);
    setSearchParams(searchParams);
    window.history.replaceState(null, "", `${window.location.pathname}?${params.toString()}`);
  }
  const onSpoofChange = (e: any) => {
    const value = e?.target?.value;
    const params = new URLSearchParams(window.location.search);
    params.set("spoof_attempt", value);
    searchParams.set("spoof_attempt", value);
    setSearchParams(searchParams);
    window.history.replaceState(null, "", `${window.location.pathname}?${params.toString()}`);
  }  

  const onSigninChange = (e: any) => {
    const value = e?.target?.value;
    const params = new URLSearchParams(window.location.search);
    params.set("mf_count_signin", value);
    searchParams.set("mf_count_signin", value);
    setSearchParams(searchParams);
    window.history.replaceState(null, "", `${window.location.pathname}?${params.toString()}`);
  }

  const renderGear = () => {
    if (!configuration?.multiframelogin) return
    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            size={"icon"}
            className="shadow-lg fixed right-44 top-5 max-md:top-[-2.8rem] max-md:absolute max-md:left-[0px] text-[14px] hover:opacity-90 hover:bg-primary rounded-[10px] hover:text-white max-md:w-[100%] max-md:text-[13px] max-md:max-w-[80px]"
          >
            <GearIcon className="h-4 w-4" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-60">
          <div className="grid gap-4">
            <div className="space-y-2">
              <h4 className="font-medium leading-none">Configuration</h4>
              <p className="text-sm text-muted-foreground">
                Set the configuration parameters.
              </p>
            </div>
            <div className="grid gap-2">
              <div className="grid grid-cols-3 items-center gap-2">
                <Label htmlFor="height" className="text-[12px]">Register frames</Label>
                <Input
                  id="retries"
                  defaultValue="3"
                  value={searchParams.get("mf_count")}
                  onChange={onRegisterChange}
                  className="col-span-2 h-8"
                />
              </div>
              <div className="grid grid-cols-3 items-center gap-2">
                <Label htmlFor="height" className="text-[12px]">Login frames</Label>
                <Input
                  id="retries"
                  defaultValue="3"
                  value={searchParams.get("mf_count_signin")}
                  onChange={onSigninChange}
                  className="col-span-2 h-8"
                />
              </div>
              <div className="grid grid-cols-3 items-center gap-2">
                <Label htmlFor="height" className="text-[12px]">Spoof attempts</Label>
                <Input
                  id="retries"
                  defaultValue="5"
                  value={searchParams.get("spoof_attempt")}
                  onChange={onSpoofChange}
                  className="col-span-2 h-8"
                />
              </div>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    );
  };

  return (
    <div className="flex px-[24px] py-[20px] max-md:p-[10px] max-md:py-[20px] items-center">
      <img
        src={isChase ? logo : MainLogo}
        alt=""
        data-src={isChase ? logo : MainLogo}
        className={`lazyload w-[160px] max-md:w-[110px]`}
      />
      <div className="flex flex-1 justify-end items-center">
        {configuration?.consumerButtons &&
          EQUIFAX_FLOWS?.map((flow) => {
            const shadow =
              activeFlow === flow
                ? "!shadow-[inset_0_4px_7px_0px_rgba(0,0,0,0.3)]"
                : "";
            return (
              <Button
                onClick={() => onChangeFlow(flow)}
                className={`w-[130px] ${shadow}  border-primary border border-solid text-primary bg-transparent rounded-[24px]  text-[14px] hover:opacity-90 max-md:w-[100%] max-md:text-[13px] max-md:max-w-[80px] mr-[10px]`}
              >
                {flow}
              </Button>
            );
          })}
        {renderGear()}
        {user?.user || user?.uuid ? (
          <>
            <div className="relative">
              <div className="flex items-center" onClick={toggelMenu}>
                <div className="relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 mt-[-5px] cursor-pointer z-50">
                  <svg
                    className="absolute w-10 h-10 text-gray-400 -left-1"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <ChevronDown
                  className={`cursor-pointer w-[20px] ml-[5px] ${
                    isMenu && "rotate-180"
                  } transition-all`}
                />
              </div>
              {isMenu && (
                <>
                  <div
                    id="userDropdown"
                    className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600 overflow-hidden absolute right-[-40px] top-[50px]"
                  >
                    <ul
                      className="text-sm text-gray-700 dark:text-gray-200"
                      aria-labelledby="avatarButton"
                    >
                      {/* <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                        onClick={() => {}}
                      >
                        Delete account
                      </li> */}
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                        onClick={onGeneratePasskey}
                      >
                        Generate passkey
                      </li>
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                        onClick={dropPasskey}
                      >
                        Drop passkey
                      </li>
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                        onClick={handleDeleteUser}
                      >
                        Delete User
                      </li>
                      <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                        onClick={dropPasskeyWithCard}
                      >
                        Drop passkey on card
                      </li>
                    </ul>
                  </div>
                  <div
                    className="fixed left-0 top-0 w-full h-full"
                    onClick={toggelMenu}
                  />
                </>
              )}
            </div>
            <Button
              className="w-[130px] bg-transparent border-2 border-solid border-primary rounded-[24px] text-[14px] hover:opacity-90 hover:bg-primary text-primary hover:text-white max-md:w-[100%] max-md:text-[13px] max-md:max-w-[80px] ml-[10px]"
              onClick={onLogout}
            >
              Logout
            </Button>
          </>
        ) : (
          <Button
            className="w-[130px] bg-primary rounded-[24px] text-[14px] hover:opacity-90 hover:bg-primary text-white max-md:w-[100%] max-md:text-[13px] max-md:max-w-[80px]"
            onClick={onSignin}
          >
            Sign in
          </Button>
        )}
      </div>
    </div>
  );
};

export default Header;
