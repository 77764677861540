import { useContext, useEffect, useState } from "react";
import lock from "assets/lock.svg";
import useCameraPermissions from "hooks/useCameraPermissions";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import useEnrollOneFaWithLiveness from "hooks/useEnrollOneFaWithLiveness";
import { UserContext } from "context/userContext";
import {
  closeCamera,
  convertCroppedImage,
  updateTypeEnum,
  updateUserWithSession,
  uploadEnrollImageWithSession,
} from "@privateid/cryptonets-web-sdk";
import Layout from "common/layout";
import BackButton from "common/components/backButton";
import CameraComponent from "common/components/camera";
import { ELEMENT_ID } from "constant";
import SwitchDeviceSelect from "common/components/switchDeviceSelect";
import { useNavigation } from "utils/onNavigate";
import { stopCamera } from "utils";
import { FACE_ANIMATION_DURATION } from "constant/animation";

type Props = {
  heading?: string;
};

function FaceScan(Props: Props) {
  const context = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const configuration: any = context?.configuration;
  const currentStepIndex = configuration?.appPages?.findIndex(
    (item: any) => item?.id === pageConfig?.id
  );
  const nextStep =
    configuration?.appPages?.[currentStepIndex + 1]?.currentRoute;
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { navigateToNextPage } = useNavigation();
  const { isCameraGranted } = useCameraPermissions(() => {});
  const [scanCompleted, setScanCompleted] = useState(false);
  const onSuccess = async () => {
    await closeCamera(ELEMENT_ID);
    await stopCamera();
    setTimeout(async () => {
      if (context?.isFaceScan) {
        context?.setIsFaceScan(false);
        navigateToNextPage("", "/generate-passkey", "/face-scan");
      } else if (nextStep === "/doc-selection") {
        navigateToNextPage('', "/doc-selection", '/face-scan');
      } else if (context?.isGenerateUuid) {
        navigateToNextPage("", "/waiting", "/face-scan");
      } else if (pageConfig) {
        navigateToNextPage();
      } else {
        navigateWithQueryParams("/doc-selection");
      }
    }, 2000);
  };
  const onApiFailure = async () => {
    await closeCamera(ELEMENT_ID);
    stopCamera();
    navigateWithQueryParams("/failed");
  };
  const {
    enrollGUID,
    enrollPUID,
    enrollStatus,
    enrollValidationStatus,
    enrollUserOneFa,
    enrollImageData,
    progress,
  }: any = useEnrollOneFaWithLiveness(() => {}, onApiFailure);
  useEffect(() => {
    if (enrollImageData && enrollGUID) {
      onFaceSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrollImageData, enrollGUID]);

  const alreadyEnrolled = enrollValidationStatus === "User Already Enrolled";
  const onFaceSuccess = async () => {
    setScanCompleted(true);
    context.setUser({
      ...context.user,
      uuid: enrollPUID,
      guid: enrollGUID,
      enrollImageData: enrollImageData,
      alreadyEnrolled,
    });
    if (context?.isGenerateUuid) {
      localStorage.setItem("uuid", JSON.stringify(enrollPUID || {}));
      return;
    }
    await updateUserWithSession({
      sessionToken: context?.tokenParams,
      updateType: updateTypeEnum.enroll,
      uuid: enrollPUID,
      guid: enrollGUID,
    });

    if (enrollImageData) {
      const enrollPortraitBase64 = await convertCroppedImage(
        enrollImageData.data,
        enrollImageData.width,
        enrollImageData.height
      );
      if (configuration?.uploadToServer) {
        await uploadEnrollImageWithSession({
          sessionToken: context?.tokenParams,
          imageString: enrollPortraitBase64,
        });
      }
      localStorage.setItem("uuid", JSON.stringify(enrollPUID || {}));
    }
  };

  const onCameraReady = () => {
    setTimeout(() => {
      enrollUserOneFa("", false, "collection_d", "test");
    }, FACE_ANIMATION_DURATION);
  };
  console.log(enrollStatus,'enrollStatus');
  
  return (
    <>
      <Layout removeHeight={!isCameraGranted}>
        <div className="px-10 py-8 max-md:p-[20px] max-md:pt-[20px]">
          <div className="flex justify-between relative">
            <BackButton isCamera />
            <div className="bg-backgroundLightGray py-[5px] px-[15px] text-[12px] rounded-[20px] flex items-center m-auto">
              <img src={lock} alt="lock" className="mr-[5px]" />
              {pageConfig?.title || "Biometrics never leave this device"}
            </div>
          </div>
          <div className="mt-[50px] rounded-[20px] flex flex-col items-center justify-center">
            <CameraComponent
              faceCamera={true}
              onCameraReady={onCameraReady}
              progress={progress}
              message={enrollValidationStatus}
              onSuccess={onSuccess}
              attempt={enrollStatus || enrollStatus === 0 ? 1 : 0}
              scanCompleted={scanCompleted}
            />
          </div>
        </div>
        <div className="mt-[-40px]">
          <SwitchDeviceSelect />
        </div>
      </Layout>
    </>
  );
}

export default FaceScan;
