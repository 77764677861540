import { Button } from "components/ui/button";
import { Label } from "components/ui/label";
import { UserContext } from "context/userContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "V2/layout";
import { ACCOUNT_NOT_APPROVED } from "constant";

import cross from "../../assets/V2/close.png";

interface componentNameProps {}

const FailureScreenV2 = (props: componentNameProps) => {
  const navigate = useNavigate();
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  return (
    <Layout logoBackgroundColor={"rgb(22,27,37)"} backgroundColor={"#161b25"}>
      <div className="pt-[50px] max-md:px-[8px] px-[40px] h-[100%] flex flex-col justify-between pb-[30px]">
        <div className="flex-grow ">
          <Label className="text-[32px] font-[500] text-white block text-center">
            Failure!
          </Label>
          <Label className="text-[15px]  font-[400] text-white block text-center opacity-80">
            {pageConfig?.message
              ? pageConfig?.message
              : context?.failedMessage || ACCOUNT_NOT_APPROVED}
          </Label>

          <div className="h-[80%] w-[100%] flex items-center justify-center ">
            <img src={cross} className="w-[40%] transform animate-custom-scale" alt="" />
          </div>
        </div>
        <div>
          <Button
            className="w-full text-black bg-white text-[16px] rounded-[16px] mt-4 h-[56px] hover:opacity-90 hover:bg-white"
            onClick={() => navigate(-1)}
          >
            Rescan
          </Button>
          <Button
            className="w-full text-black bg-white text-[16px] rounded-[16px] mt-4 h-[56px] hover:opacity-90 hover:bg-white"
            onClick={() => {
              navigate("/");
            }}
          >
            Return to homepage
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default FailureScreenV2;
