import { Label } from "components/ui/label";
import * as React from "react";
import Camera from "V2/camera";
import Layout from "V2/layout";
import useEnrollOneFaWithLiveness from "hooks/useEnrollOneFaWithLiveness";
import { UserContext } from "context/userContext";
import { UAParser } from "ua-parser-js";
import {
  closeCamera,
  convertCroppedImage,
  updateTypeEnum,
  updateUserWithSession,
  uploadEnrollImageWithSession,
  verifyIdWithSession,
  verifySessionTokenV2,
} from "@privateid/cryptonets-web-sdk";
import cardBg from "assets/V2/cardBg.png";
import { Button } from "components/ui/button";
import scan from "../../assets/V2/scan.gif";
import { useNavigation } from "utils/onNavigate";
import { ELEMENT_ID } from "constant";
import { getFirstRequirement, isMobile, stopCamera } from "utils";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import { issueCredentials } from "services/vc-dock";
import UserInfo from "V2/userInfo";

interface componentNameProps {}
let loaded;
const FaceScan = () => {
  const context = React.useContext(UserContext);
  const configuration: any = context?.configuration;
  const pageConfig: any = context?.pageConfiguration;
  const { browser } = UAParser(navigator.userAgent);
  const isMobileSafari = browser?.name === "Mobile Safari";
  const [doneStatus, setDoneStatus] = React.useState(false);
  const { navigateToNextPage } = useNavigation();
  const [showCorners, setShowCorners] = React.useState(false);
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const [showPhone, setShowPhone] = React.useState(true);
  const onApiFailure = async () => {
    await closeCamera(ELEMENT_ID);
    stopCamera();
    navigateWithQueryParams("/v2/failure-screen");
  };
  const {
    enrollGUID,
    enrollPUID,
    enrollStatus,
    enrollValidationStatus,
    enrollUserOneFa,
    enrollImageData,
    progress,
    showInstruction,
    stopInstructionTimer,
  }: any = useEnrollOneFaWithLiveness(() => {}, onApiFailure);
  React.useEffect(() => {
    if (enrollImageData && enrollGUID && !showPhone) {
      onFaceSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrollImageData, enrollGUID, showPhone]);
  const alreadyEnrolled = enrollValidationStatus === "User Already Enrolled";
  const onFaceSuccess = async () => {
    // await closeCamera(ELEMENT_ID);
    // await stopCamera();
    // setScanCompleted(true);
    context.setUser({
      ...context.user,
      uuid: enrollPUID,
      guid: enrollGUID,
      enrollImageData: enrollImageData,
      alreadyEnrolled,
    });
    await updateUserWithSession({
      sessionToken: context?.tokenParams,
      updateType: updateTypeEnum.enroll,
      uuid: enrollPUID,
      guid: enrollGUID,
    });
    setDoneStatus(true);
    setTimeout(() => {
       if (context?.isGenerateUuid) {
        onVerify()
      } else {
        navigateToNextPage();
      }
    }, 3000);
    if (enrollImageData) {
      const enrollPortraitBase64 = await convertCroppedImage(
        enrollImageData.data,
        enrollImageData.width,
        enrollImageData.height
      );
      if (configuration?.uploadToServer) {
        await uploadEnrollImageWithSession({
          sessionToken: context?.tokenParams,
          imageString: enrollPortraitBase64,
        });
      }
      localStorage.setItem("uuid", JSON.stringify(enrollPUID || {}));
    }
  };


  const onVerify = async () => {
    loaded = true;
    await verifyIdWithSession({
      sessionToken: context?.tokenParams,
    });
    const verifyTokenRes = await verifySessionTokenV2({
      sessionToken: context?.tokenParams,
    });
    enum tokenStatus {
      PENDING = "PENDING",
      SUCCESS = "SUCCESS",
      FAILURE = "FAILURE",
      REQUIRES_INPUT = "REQUIRES_INPUT",
    }
    if (verifyTokenRes.status === tokenStatus.SUCCESS) {
      // Success
      loaded = false;
      localStorage.setItem("user", JSON.stringify(verifyTokenRes));
      context?.setV2SuccessMessage("You're signed up!");
      navigateToNextPage("", "/v2/success", "/v2/scan-face");
      await issueVC(verifyTokenRes.user, true);
    } else if (verifyTokenRes.status === tokenStatus.FAILURE) {
      loaded = false;
      navigateWithQueryParams("/v2/failure-screen");
    } else if (verifyTokenRes.status === tokenStatus.REQUIRES_INPUT) {
      getRequirements(verifyTokenRes?.dueRequirements);
    } else if (verifyTokenRes.status === tokenStatus.PENDING) {
      loaded = false;
      navigateWithQueryParams("/v2/failure-screen");
    }
  };

  const getRequirements = async (requirement: any) => {
    const requirementStep = await getFirstRequirement(requirement, context);
    loaded = false;
    switch (requirementStep) {
      case "requestSSN9":
        return navigateWithQueryParams("/ssn");
      case "requireResAddress":
        return navigateWithQueryParams("/address");
      case "requestScanID":
        return navigateWithQueryParams("/drivers-licence-intro");
      default:
        break;
    }
  };

  const issueVC = async (userId: string, fullInformation: boolean) => {
    try {
      await issueCredentials(userId, fullInformation);
    } catch (e) {
      console.log({ e }, "error issueVC");
    }
  };

  const onCameraReady = () => {
    setShowCorners(true);
    enrollUserOneFa("", false, "collection_d", "test");
  };

  const onSuccess = () => {
    setShowPhone(false);
  };
  const isGreenBorder = progress > 90;
  return (
    <Layout
      camera
      innerContainer="max-md:mt-[-78px] max-md:!h-[calc(100vh_-_0rem)]"
    >
      <div className="px-[40px] min-h-full flex flex-col justify-between relative max-md:pt-[40px]">
        {showCorners && (
          <div
            className={`recognition-box  relative z-10 ${
              progress > 60
                ? isMobile
                  ? "!w-[50%] !h-[30%]"
                  : "!w-[35%] !h-[28%]"
                : isMobile
                ? "!w-[80%]"
                : "!w-[50%]"
            }`}
          >
            <div
              className={`corner cornerWhite top-left ${
                isGreenBorder ? "greenBorder" : ""
              }`}
            ></div>
            <div
              className={`corner  cornerWhite  top-right ${
                isGreenBorder ? "greenBorder" : ""
              }`}
            ></div>
            <div
              className={`corner  cornerWhite bottom-left ${
                isGreenBorder ? "greenBorder" : ""
              }`}
            ></div>
            <div
              className={`corner  cornerWhite bottom-right ${
                isGreenBorder ? "greenBorder" : ""
              }`}
            ></div>
          </div>
        )}
        {showPhone && pageConfig?.type === "phone-input" && (
          <div className="absolute top-[0px] left-[0px] z-[99999999] w-full h-full backdrop-blur-lg pt-[50px]">
          <UserInfo onSuccess={onSuccess} hideCamera={true} isCameraScreen={true} />
          </div>
        )}
        <Camera
          onCameraReady={onCameraReady}
          message={enrollValidationStatus || "Center your head in the frame"}
          progress={progress}
        />
        {doneStatus && (
          <div
            className={`${
              doneStatus
                ? `absolute  top-0 left-0 w-full flex flex-col items-center justify-center h-[705px] z-[9999999999] backdrop-blur-lg ${
                    isMobileSafari
                      ? "max-md:h-[calc(100vh_-_4rem)]"
                      : "max-md:h-[calc(100vh_-_2rem)]"
                  }  ${
                    window.innerWidth < 767
                      ? isMobileSafari
                        ? "[@media_((min-height:700px)_and_(max-height:1000px))]:h-[calc(100vh_-_0rem)]"
                        : "[@media_((min-height:700px)_and_(max-height:730px))]:h-[calc(100vh_-_0rem)] [@media_((min-height:731px)_and_(max-height:750px))]:h-[calc(100vh_-_10rem)] [@media_((min-height:751px)_and_(max-height:1000px))]:h-[calc(100vh_-_0rem)]"
                      : ""
                  }`
                : ""
            }`}
          >
            <div
              className="bg-[#161B25B2] w-[100%] h-[100%] flex items-center justify-center successAnimationNewv2"
              style={{
                // backgroundImage: `url(${cardBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="text-[25px] absolute top-[62px] text-center left-[0px] z-[99999999] w-full bg-transparent bg-opacity-70 text-[#cbd4eb] py-2 rounded-lg">
                Done! Image deleted
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="svg-success"
                viewBox="0 0 24 24"
              >
                <g
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                >
                  <circle
                    className="success-circle-outline"
                    cx="12"
                    cy="12"
                    r="11.5"
                  />
                  <circle
                    className="success-circle-fill"
                    cx="12"
                    cy="12"
                    r="11.5"
                  />
                  <polyline
                    className="success-tick"
                    points="17,8.5 9.5,15.5 7,13"
                  />
                </g>
              </svg>
            </div>
          </div>
        )}
        {showInstruction && (
          <div
            className="absolute top-0 left-0 h-[705px] w-full instructionAnimation"
            style={{
              backgroundImage: `url(${cardBg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="pt-[50px] max-md:px-[20px] px-[40px] h-[100%] flex flex-col justify-between ">
              <div className="flex-grow">
                <Label className="text-[32px] font-[500] text-white block text-center">
                  Set up Face ID
                </Label>
                <Label className="text-[15px]  font-[400] text-white block text-center opacity-80">
                  {enrollValidationStatus || "Center your head in the frame"}
                </Label>
                <div className=" pt-[90px] mx-auto  flex justify-center">
                  <img src={scan} className=" w-[280px] h-[250px]" />
                </div>
              </div>
              <div className="flex flex-col space-y-4 justify-center items-center pb-[15px] ">
                <Button
                  className="w-full max-md:mb-[50px] text-black bg-white rounded-[16px] mt-4 h-[56px] hover:opacity-90 hover:bg-white"
                  onClick={() => stopInstructionTimer()}
                >
                  Continue
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default FaceScan;
