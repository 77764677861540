import { Button } from "components/ui/button";
import { Label } from "components/ui/label";
import { useNavigate } from "react-router-dom";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import Layout from "V2/layout";
import scan from "../../assets/V2/scan.gif";

interface componentNameProps {}

const FaceId = (props: componentNameProps) => {
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  return (
    <Layout>
      <div className="pt-[50px] max-md:px-[20px] px-[40px] h-[100%] flex flex-col justify-between ">
        <div className="flex-grow">
          <Label className="text-[32px] font-[500] text-white block text-center">
            Set up Face ID
          </Label>
          <Label className="text-[15px]  font-[400] text-white block text-center opacity-80">
            Scan your face to verify identity
          </Label>
          <div className=" pt-[90px] mx-auto  flex justify-center">
            <img src={scan} className=" w-[280px] h-[250px]" />
          </div>
        </div>
        <div className="flex flex-col space-y-4 justify-center items-center pb-[15px] ">
          <Button
            className="w-full max-md:mb-[50px] text-black bg-white rounded-[16px] mt-4 h-[56px] hover:opacity-90 hover:bg-white"
            onClick={() => navigateWithQueryParams("/v2/scan-face")}
          >
            Continue
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default FaceId;
