/* eslint-disable @typescript-eslint/no-use-before-define */
import { useContext, useEffect, useState } from "react";
import {
  convertCroppedImage,
  documentMugshotFaceCompare,
  scanFrontDocument as scanFrontDocumentModule,
} from "@privateid/cryptonets-web-sdk";
import { extractRoute } from "../utils";
import Rerun from "../utils/reRuncheck";
import { useToast } from "components/ui/use-toast";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import { UserContext } from "context/userContext";

const useScanFrontDocument = (
  onSuccess: ({
    croppedDocument,
    inputImage,
    croppedMugshot,
    portraitConfScore,
  }: {
    croppedDocument: string | null;
    inputImage: string | null;
    croppedMugshot: string | null;
    portraitConfScore: number;
  }) => void,
  enrollImageData: ImageData,
  redirect?: string
) => {
  const context: any = useContext(UserContext);
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { toast } = useToast();
  const [isFound, setIsFound] = useState(false);
  const [resultStatus, setResultStatus] = useState(null);
  const [scanStatus, setScanStatus] = useState("");
  // raw byte
  const [inputImageData, setInputImageData] = useState<any>(null);
  const [croppedDocumentRaw, setCroppedDocumentRaw] = useState<any>(null);
  const [croppedMugshotRaw, setCroppedMugshotRaw] = useState<any>(null);
  console.log(
    inputImageData,
    "inputImageData",
    croppedDocumentRaw,
    croppedMugshotRaw
  );

  // base64 image
  const [inputImageBase64, setInputImageBase64] = useState(null);
  const [croppedDocumentBase64, setCroppedDocumentBase64] = useState(null);
  const [croppedMugshotBase64, setCroppedMugshotBase64] = useState(null);

  // confidence value
  const [returnValue, setResultValue] = useState<any>({});
  const [frontScanData, setFrontScanData] = useState(null);

  // Imagedata to base64
  useEffect(() => {
    if (isFound && enrollImageData && returnValue) {
      console.log("ImageData", enrollImageData);
      if (enrollImageData?.data?.length) {
        doConvert("cropped image data:", enrollImageData).then((data: any) =>
          setInputImageBase64(data)
        );
      }
    }
  }, [isFound, enrollImageData, returnValue]);

  // croppedMugshotRaw to base64
  useEffect(() => {
    if (isFound && croppedMugshotRaw && returnValue) {
      const image = new ImageData(
        croppedMugshotRaw,
        returnValue.cropped_mugshot.width,
        returnValue.cropped_mugshot.height
      );
      console.log("MugshotImageData", image);
      if (image?.data?.length) {
        doConvert("cropped mugshot image:", image).then((data: any) =>
          setCroppedMugshotBase64(data)
        );
      }
    }
  }, [isFound, croppedMugshotRaw, returnValue]);

  // croppedDocumentRaw to base64
  useEffect(() => {
    if (
      isFound &&
      croppedDocumentRaw &&
      returnValue?.cropped_document &&
      resultStatus === 0
    ) {
      const expectedLength =
        4 *
        returnValue.cropped_document.width *
        returnValue.cropped_document.height;
      console.log("croppedDocumentRaw length:", croppedDocumentRaw.length);
      console.log("Expected length:", expectedLength);

      if (croppedDocumentRaw.length === expectedLength) {
        const image = new ImageData(
          croppedDocumentRaw,
          returnValue?.cropped_document?.width,
          returnValue?.cropped_document?.height
        );
        console.log("DocumentImageData", image);
        if (image.data.length) {
          doConvert("cropped document image:", image).then((data: any) =>
            setCroppedDocumentBase64(data)
          );
        }
      } else {
        console.error("Mismatch in ImageData length");
      }
    }
  }, [isFound, returnValue]);

  const doConvert = async (message: string, imageData: any) => {
    try {
      if (
        imageData?.data?.length ===
        imageData?.width * imageData?.height * 4
      ) {
        const b64Converted = await convertCroppedImage(
          imageData.data,
          imageData.width,
          imageData.height
        );
        console.log(`${message}`, b64Converted);
        return await b64Converted;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const documentCallback = (result: any) => {
    console.log("Front scan callback result:", result);
    setResultStatus(result?.doc_validation_status);
    setFrontScanData(result);
    RerunAction.RerunAction = false;
    if (result.doc_validation_status === 0) {
      RerunAction.clearCheck();

      setResultValue(result);
    } else {
      scanFrontDocument();
    }
  };

  const doCompare = async () => {
    const croppedDocument = new ImageData(
      // @ts-ignore
      croppedDocumentRaw,
      returnValue?.cropped_document?.width,
      returnValue?.cropped_document?.height
    );

    await documentMugshotFaceCompare(
      faceCompareCallback,
      enrollImageData,
      croppedDocument,
      {
        input_image_format: "rgba",
        // @ts-ignore
        // auto_zoom_disabled: false,
      }
    );
  };

  const loadPageConfiguration = () => {
    const currentRouteIndex = context?.configuration?.appPages?.findIndex(
      (item: any) => item?.currentRoute === (redirect || "/face-scan-intro")
    );
    const activeIndex = currentRouteIndex;
    context?.setPageIndex(activeIndex);
    const pageConfiguration = extractRoute(context?.configuration, activeIndex);
    context?.setPageConfiguration(pageConfiguration);
  };

  useEffect(() => {
    if (
      isFound &&
      croppedDocumentRaw &&
      inputImageBase64 &&
      croppedMugshotBase64 &&
      croppedDocumentBase64
    ) {
      if (enrollImageData) {
        doCompare();
      } else {
        toast({
          variant: "destructive",
          description: "Image data not found. Please scan your face again.",
        });
        loadPageConfiguration();
        navigateWithQueryParams(redirect || "/face-scan-intro");
      }
    } else if (!inputImageBase64 && !enrollImageData) {
      toast({
        variant: "destructive",
        description: "Image data not found. Please scan your face again.",
      });
      loadPageConfiguration();
      navigateWithQueryParams(redirect || "/face-scan-intro");
    }
  }, [
    isFound,
    croppedDocumentRaw,
    inputImageBase64,
    croppedMugshotBase64,
    croppedDocumentBase64,
  ]);

  const faceCompareCallback = async (result: any) => {
    const { compare_score } = result;
    console.log("COMPARE RESULT", compare_score, inputImageBase64, result);
    // if (compare_score < 30) {
    //   setScanStatus("Please rescan driver’s license");
    //   setTimeout(() => {
    //     reScanFrontDocument();
    //   }, 3000);
    // } else {
    onSuccess({
      inputImage: inputImageBase64,
      croppedDocument: croppedDocumentBase64,
      croppedMugshot: croppedMugshotBase64,
      portraitConfScore: compare_score,
    });
    // }
  };

  const scanFrontDocument = async () => {
    RerunAction.doInterval();
    const {
      result: resultData,
      croppedDocument,
      croppedMugshot,
      imageData,
    }: any = await scanFrontDocumentModule({
      callback: documentCallback,
      image: undefined,
      config: {
        blur_threshold_doc: 1450,
      },
    });
    console.log("hook response:", {
      croppedDocument,
      croppedMugshot,
      imageData,
      resultData,
    });
    if (
      imageData &&
      croppedDocument &&
      croppedMugshot &&
      resultData !== "error"
    ) {
      setIsFound(true);
      setInputImageData(imageData);
      setCroppedDocumentRaw(croppedDocument);
      setCroppedMugshotRaw(croppedMugshot);
    } else if (imageData && croppedDocument && resultData !== "error") {
      scanFrontDocument();
    }
  };

  const RerunAction = new Rerun(scanFrontDocument);

  return {
    scanFrontDocument,
    isFound,
    setIsFound,
    resultStatus,
    inputImageData,
    croppedMugshotRaw,
    croppedDocumentRaw,
    scanStatus,
    frontScanData,
  };
};

export default useScanFrontDocument;
