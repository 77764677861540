import bloom from "assets/V2/bloom.jpg";
import { UserContext } from "context/userContext";
import { useContext } from "react";
import { useNavigation } from "utils/onNavigate";

const Landing = () => {
  const { setLoginOption }: any = useContext(UserContext);
  const { navigateToNextPage } = useNavigation();

  const onSignup = () => {
    navigateToNextPage("", "/v2/consent", "/");
  };
  const onLogin = () => {
    setLoginOption("prove-checkout");
    navigateToNextPage("", "/v2/login", "/");
  };

  const onReset = () => {
    setLoginOption("prove-reset");
    navigateToNextPage("", "/v2/login", "/");
  };

  return (
    <div>
      <div className="flex justify-end p-[20px]">
        <button className="text-primary hover:underline" onClick={onLogin}>
          Sign in
        </button>
        <button
          className="ml-[20px] bg-primary text-white px-[30px] py-[4px] rounded-[40px] pb-[6px]"
          onClick={onSignup}
        >
          Sign up
        </button>
      </div>
      <div>
        <img src={bloom} alt="bloom" className="m-auto" />
      </div>
      <div className="fixed right-[20px] bottom-[20px] opacity-30">
        <span className="cursor-pointer hover:underline" onClick={onReset}>Reset</span>
      </div>
    </div>
  );
};

export default Landing;
