import { useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import {
  FACE_ANIMATION_COMPLETED_DURATION,
  FACE_ANIMATION_DURATION,
  FACE_ANIMATION_INPROGRESS_DURATION,
} from "constant/animation";

const FaceAnimation = ({
  children,
  isCircle,
  isScanned,
  handleAnimationComplete,
  enrollOneFaProgress,
  showProgress,
}: any) => {
  const [completed, setCompleted] = useState(false);
  const withProgress = showProgress && enrollOneFaProgress > 0;

  const useSpringConfig = {
    borderRadius: isScanned ? "50%" : isCircle ? "150px" : "16px",
    maxWidth: isScanned ? "100px" : isCircle ? "300px" : "550px",
    maxHeight: isScanned ? "100px" : isCircle ? "300px" : "350px",
    width: "100%",
    height: "100%",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    config: {
      duration: isScanned
        ? FACE_ANIMATION_INPROGRESS_DURATION
        : completed
        ? FACE_ANIMATION_COMPLETED_DURATION
        : FACE_ANIMATION_DURATION,
    },
    onRest: () => {
      if (handleAnimationComplete) {
        requestAnimationFrame(() =>
          handleAnimationComplete(isScanned ? "completed" : "start")
        );
      }
    },
  };

  const animatedProgress = useSpring({
    to: { progress: enrollOneFaProgress },
    config: { duration: 120 },
    reset: false,
  });

  const styles: any = useSpring(useSpringConfig);

  const borderWith: any = useSpring({
    width: isCircle ? "155" : "40px",
    height: isCircle ? "155" : "40px",
    borderTopLeftRadius: isCircle ? "150px" : "15px",
    borderStyle: "solid",
    opacity: completed ? "0" : "1",
    margin: completed ? "-5px" : "0px",
    config: { duration: FACE_ANIMATION_DURATION },
    onRest: () => {
      setCompleted(true);
    },
  });

  return (
    <animated.div
      style={{
        position: "relative",
        borderRadius: isScanned ? "50%" : isCircle ? "150px" : "16px",
        border:
          !isScanned && completed ? "3px dashed #fff" : "3px solid transparent",
        left: 0,
        top: 0,
        ...styles,
      }}
    >
      <animated.div
        style={{
          position: "absolute",
          overflow: "hidden",
          transform: "scale(1.03)",
          padding: isCircle ? "7px" : "0px",
          filter: isScanned ? "blur(5px)" : "blur(0px)",
          backgroundImage: withProgress
            ? animatedProgress.progress.to(
                (p: string) =>
                  `conic-gradient(#4CAF50, #4CAF50 ${p}%, transparent ${p}%)`
              )
            : "none",
          ...styles,
        }}
      >
        <animated.div
          style={{
            ...borderWith,
            position: "absolute",
            top: 0,
            left: 0,
            borderTop: "3px solid #fff",
            borderLeft: "3px solid #fff",
          }}
        ></animated.div>

        <animated.div
          style={{
            ...borderWith,
            position: "absolute",
            top: 0,
            left: 0,
            borderTop: "3px solid #fff",
            borderLeft: "3px solid #fff",
          }}
        ></animated.div>
        <animated.div
          style={{
            ...borderWith,
            position: "absolute",
            top: 0,
            right: 0,
            transform: "scaleX(-1)",
            borderLeft: "3px solid #fff",
            borderTop: "3px solid #fff",
          }}
        ></animated.div>
        <animated.div
          style={{
            ...borderWith,
            position: "absolute",
            bottom: 0,
            left: 0,
            transform: "scaleY(-1)",
            borderLeft: "3px solid #fff",
            borderTop: "3px solid #fff",
          }}
        ></animated.div>
        <animated.div
          style={{
            ...borderWith,
            position: "absolute",
            bottom: 0,
            right: 0,
            transform: "rotate(180deg)",
            borderLeft: "3px solid #fff",
            borderTop: "3px solid #fff",
          }}
        ></animated.div>
        {children}
      </animated.div>
    </animated.div>
  );
};

export default FaceAnimation;