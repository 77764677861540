import { Label } from "components/ui/label";
import { useNavigate } from "react-router-dom";
import Layout from "V2/layout";

interface componentNameProps {}

const LogInMobile = (props: componentNameProps) => {
  const navigate = useNavigate();
  return (
    <Layout logoBackgroundColor={"rgb(22,27,37)"}  backgroundColor={"#161b25"}>
      <div className="pt-[50px] max-md:px-[8px] px-[40px] h-[100%] flex flex-col justify-between ">
        <div className="flex-grow">
        <Label className="text-[25px] font-[500] text-white block text-center">
        Log in successfully!
          </Label>
        
          <div className="h-[80%] w-[100%] flex items-center justify-center ">
         
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="svg-success"
              viewBox="0 0 24 24"
            >
              <g
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
              >
                <circle
                  className="success-circle-outline"
                  cx="12"
                  cy="12"
                  r="11.5"
                />
                <circle
                  className="success-circle-fill"
                  cx="12"
                  cy="12"
                  r="11.5"
                />
                <polyline
                  className="success-tick"
                  points="17,8.5 9.5,15.5 7,13"
                />
              </g>
            </svg>
          </div>


        </div>
       
      </div>
    </Layout>
  );
};

export default LogInMobile;
