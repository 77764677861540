import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import completedCheck from "Animations/4-Capture-successfully/JSON/confetti.json";
import cameraIcon from "assets/cameraIcon.svg";
import deniedCameraIcon from "assets/deniedCamera.svg";
import { Button } from "components/ui/button";
import { AllowCameraModal } from "../allowCameraModal";
import useWasm from "hooks/useWasm";
import useCamera from "hooks/useCamera";
import {
  CameraConfig,
  ENROLL_CANVAS_RESOLUTION,
  SWITCH_DEVICE,
} from "constant";
import { switchCamera } from "@privateid/cryptonets-web-sdk";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Loader2 } from "lucide-react";
import useCameraPermissions from "hooks/useCameraPermissions";
import FaceAnimation from "common/animateCircle/faceScanAnimation";
import { isMobile } from "utils";
import DocumentProgressAnimation from "common/animateCircle/documentProgressAnimation";
import DocumentAnimation from "common/animateCircle/documentAnimation";
import { detect, OperatingSystem } from "detect-browser";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import { useLocation } from "react-router-dom";
import frontDlImage from "assets/frontDl.svg";
import backDlImage from "assets/backDl.svg";

type Props = {
  heading?: string;
  faceCamera?: boolean;
  frontDl?: boolean;
  backDl?: boolean;
  passportScan?: boolean;
  faceLoginCamera?: boolean;
  onCameraReady?: () => void;
  progress?: number;
  message?: string | undefined | null;
  onSuccess?: () => void;
  attempt?: number;
  scanCompleted?: boolean;
  onCameraSwitch?: () => void;
  cameraFail?: () => void;
};

const rendererSettings = {
  preserveAspectRatio: "xMaxYMin slice",
};

function CameraComponent(props: Props) {
  const {
    faceCamera,
    frontDl,
    backDl,
    passportScan,
    faceLoginCamera,
    onCameraReady,
    progress,
    message,
    onSuccess,
    attempt,
    scanCompleted,
    onCameraSwitch,
    cameraFail,
  } = props;
  console.log(message, "message");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [minimizeCamera, setMinimizeCamera] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [completedLoginScan, setCompletedLoginScan] = useState(false);

  const browserData: any = detect() as any;
  const { os }: any = browserData;
  // const [frontDlCompleted, setFrontDlCompleted] = useState(false);
  const [deviceId, setDeviceId] = useState<string>();
  const [startAnimation, setStartAnimation] = useState(false);
  const [overlayImage, setOverlayImage] = useState(true);
  const [documentCompletedScan, setDocumentCompletedScan] = useState(false);
  const enrollOneFaProgress: any = progress
    ? Math.min(Math.ceil(progress), 100)
    : 0;
  const { isCameraGranted, state }: any = useCameraPermissions(() => {});
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const location = useLocation();

  // Camera and Wasm init
  const onCameraFail = () => {
    cameraFail?.();
  };
  const cameraReady = () => {
    onCameraReady?.();
  };
  const documentScan = frontDl || backDl || passportScan;
  const { ready: wasmReady, wasmStatus } = useWasm();
  const canvasResolution =
    !isMobile && !documentScan ? ENROLL_CANVAS_RESOLUTION : null;
  const { ready, init, device, devices } = useCamera(
    CameraConfig?.elementId,
    documentScan ? "back" : (CameraConfig?.mode as any),
    CameraConfig?.requireHD,
    onCameraFail,
    documentScan,
    canvasResolution
  );
  useEffect(() => {
    if (device) {
      setDeviceId(device);
    }
  }, [device]);
  const handleWasmLoad = () => {
    if (!wasmReady && wasmStatus.isChecking) return;
    if (wasmReady && !wasmStatus.isChecking && wasmStatus.support) {
      if (!ready) {
        init();
      } else if (isCameraGranted && ready) {
        cameraReady();
      }
    }
    if (!wasmReady && !wasmStatus.isChecking && !wasmStatus.support) {
      onCameraFail();
    }
  };
  useEffect(() => {
    handleWasmLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasmReady, ready, wasmStatus]);

  const onCompleted = () => {
    setCompleted(true);
    onSuccess?.();
  };

  const handleAnimationComplete = (state: string) => {
    if (state === "start") {
      setStartAnimation(true);
    } else if (state === "completed") {
      onCompleted();
    }
  };

  useEffect(() => {
    if ((faceCamera || faceLoginCamera) && isCameraGranted) {
      setMinimizeCamera(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faceCamera, isCameraGranted]);

  const onSwitchCamera = async (cameraId: any) => {
    if (cameraId === SWITCH_DEVICE) {
      navigateWithQueryParams(`/switch-device`, { from: location.pathname });
      return;
    }
    setDeviceId(cameraId);
    onCameraSwitch?.();
    await switchCamera(null, cameraId);
  };

  useEffect(() => {
    if (!message) {
      setTimeout(() => {
        setOverlayImage(false);
      }, 1500);
    }
  }, [message]);

  const renderVideo = () => {
    return (
      <div
        className={`bg-[#0b101b] w-full h-full ${
          (faceCamera || faceLoginCamera) && minimizeCamera && "rounded-[160px]"
        }`}
      >
        {!ready && (
          <div className="absolute h-full w-full left-0 top-0 z-50 flex items-center justify-center">
            <Loader2 className="animate-spin h-[55px] w-[55px] text-[#fff]" />
          </div>
        )}
        {overlayImage && documentScan && (
          <div className="w-full h-full flex items-center justify-center absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
            <img
              src={frontDl ? frontDlImage : backDlImage}
              alt="dl_image"
              className="w-[70%] filter invert-[100%]"
            />
          </div>
        )}
        <video
          id="userVideo"
          muted
          autoPlay
          playsInline
          className={`w-full h-full 
                    ${
                      startAnimation &&
                      (faceCamera || faceLoginCamera) &&
                      "rounded-[160px]"
                    }
                    object-cover ${
                      (faceCamera || faceLoginCamera) && "face-camera"
                    }`}
        />
      </div>
    );
  };

  const renderContent = () => {
    if (!isCameraGranted && state === "prompt") {
      return (
        <>
          <img src={cameraIcon} alt="cameraIcon" />
          <p className="text-[28px] text-white max-w-[350px]">
            Your browser will request access to your camera
          </p>
        </>
      );
    } else if (!isCameraGranted && state === "denied") {
      return (
        <>
          <img src={deniedCameraIcon} alt="cameraIcon" />
          <p className="text-[28px] text-white max-w-[350px]">
            Can not access camera
          </p>
          <p className="text-[16px] text-placeholder max-w-[350px] font-[200]">
            Access to your camera has been denied.
          </p>
          <Button
            className="max-w-[140px] w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary"
            onClick={() => setIsModalOpen(!isModalOpen)}
          >
            Allow camera
          </Button>
        </>
      );
    } else if (isCameraGranted && ["granted", "prompt"]?.includes(state)) {
      return (
        <div className="h-full w-full p-[20px] flex items-center justify-center relative">
          {documentScan && progress === 100 && !documentCompletedScan && (
            <div className="document-canvas">
              <DocumentProgressAnimation
                progressData={progress}
                setCompleted={(e: any) => setDocumentCompletedScan(e)}
              />
            </div>
          )}
          {faceCamera || faceLoginCamera ? (
            <FaceAnimation
              isCircle={(faceCamera || faceLoginCamera) && minimizeCamera}
              isScanned={
                faceLoginCamera
                  ? scanCompleted && completedLoginScan
                  : scanCompleted
              }
              handleAnimationComplete={handleAnimationComplete}
              enrollOneFaProgress={enrollOneFaProgress}
              showProgress={
                (faceLoginCamera ? !completedLoginScan : !scanCompleted) &&
                startAnimation
              }
            >
              {renderVideo()}
            </FaceAnimation>
          ) : (
            <div
              className={`${
                frontDl || backDl ? "max-md:!h-[270px]" : ""
              } w-full h-full`}
            >
              <DocumentAnimation
                isCircle={(faceCamera || faceLoginCamera) && minimizeCamera}
                isScanned={
                  documentScan
                    ? documentCompletedScan && scanCompleted
                    : scanCompleted
                }
                handleAnimationComplete={handleAnimationComplete}
                documentScan={documentScan}
              >
                {renderVideo()}
              </DocumentAnimation>
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <>
      {isCameraGranted && ["granted", "prompt"]?.includes(state) && (
        <h4 className="text-[25px] leading-[24px]  mt-[-40px] mb-[10px] max-md:text-[22px] max-md:mt-[-10px] max-md:mb-[10px]">
          {(frontDl || backDl || faceCamera) && completed
            ? `Complete${faceCamera ? ", your selfie was deleted." : ""}`
            : message
            ? message
            : faceCamera || faceLoginCamera
            ? "Center your head in the frame"
            : passportScan
            ? "Scan your passport"
            : frontDl
            ? "Position front of ID in the frame"
            : backDl
            ? "Position back of ID in the frame"
            : ""}
        </h4>
      )}
      <div className="mx-1 bg-[#0b101b] h-[380px] mt-[0px] rounded-[20px] flex flex-col items-center justify-center w-full max-md:h-[343px]">
        {completed ? (
          <Lottie
            loop={false}
            autoplay={true}
            animationData={completedCheck}
            style={{
              height: isMobile ? 320 : "100%",
            }}
            rendererSettings={isMobile ? {} : rendererSettings}
          />
        ) : (
          renderContent()
        )}
      </div>
      {completed ? (
        <div className="mb-[0px] max-md:w-[100%] max-md:mb-[30px] h-[40px]" />
      ) : (
        <div className="mb-[0px] max-md:w-[100%] max-md:mb-[30px] mt-2  max-lg:hidden">
          {isCameraGranted && ["granted", "prompt"]?.includes(state) ? (
            <Select
              onValueChange={(e: string) => onSwitchCamera(e)}
              value={
                devices?.find((device) => device?.value === deviceId)?.value
              }
            >
              <SelectTrigger
                className="h-[48px] mt-[0px] font-[400] rounded-[8px] border-borderSecondary {
                      ] text-[14px] focus:outline-none  focus:ring-transparent"
              >
                <SelectValue placeholder="Select your camera" />
              </SelectTrigger>
              <SelectContent>
                {devices.map((item: { label: string; value: string }) => {
                  return (
                    <SelectItem value={item?.value} key={item?.value}>
                      {item?.label}
                    </SelectItem>
                  );
                })}
                {os !== "iOS" && os !== "Android OS" && (
                  <SelectItem value={SWITCH_DEVICE}>
                    Switch to Mobile Device
                  </SelectItem>
                )}
              </SelectContent>
            </Select>
          ) : (
            <div style={{ height: 30 }} />
          )}
        </div>
      )}
      {isCameraGranted && ["granted", "prompt"]?.includes(state) && (
        <div className="p-5 relative w-full">
          {completed ? (
            <p className="text-[14px] font-[400] text-[#000] h-[45px]"></p>
          ) : (
            <>
              <h4 className="text-[25px] leading-[24px]">
                {enrollOneFaProgress}%
              </h4>
              <p className="text-[14px] font-[200] text-[#555]">scanned</p>
            </>
          )}
        </div>
      )}
      <AllowCameraModal
        open={isModalOpen}
        toggle={() => setIsModalOpen(!isModalOpen)}
      />
    </>
  );
}

export default CameraComponent;
